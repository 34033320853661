<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Credit Score</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">Mobile Number<span class="text-danger">*</span></label>
                                    <div class="input-group custom-input-group">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <Multiselect v-model="countrycode" :options="countryCodeList"
                                                :searchable="true" label="label" placeholder="Select" disabled
                                                class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                                :canDeselect="false" />
                                        </div>
                                        <div class="col-lg-8 col-md-8 col-12">
                                            <input type="text" :disabled="disabledinput" v-model="searchmobile"
                                                class="form-control" id="formSearchMobileNoInput"
                                                placeholder="Enter mobile number" maxlength="10"
                                                @keypress="onlyNumberMobile" autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">First Name<span class="text-danger">*</span></label>
                                    <div class="custom-form-group">
                                        <input type="text" :disabled="disabledinput" v-model="firstname"
                                            class="form-control text-capitalize" placeholder="Enter First Name"
                                            maxlength="20" @keypress="isLetterWithOutSpace($event)"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <div class="custom-form-group">
                                    <label class="form-label">PAN No.<span class="text-danger">*</span></label>
                                    <div class="custom-form-group">
                                        
                                            <input type="text" :disabled="disabledinput" v-model="pancardnumber" class="form-control text-uppercase"
                                id="formpancardinput" placeholder="Enter Pan No."
                                autocomplete="off" maxlength="10" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-2 col-12">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="header-btn-outer btn btn-primary"
                                        :style="{ width: '100px' }" @click="searchmobilewithcreditscore()"
                                        :disabled="searchmobile.length != 10 || firstname == '' || pancardnumber == '' || disabledinput">
                                        <span v-if="!btnloader">Search</span>
                                        <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="btnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                        <div class="row" >
                            <div class="col-lg-12 col-md-12 col-12" v-if="localdatafound">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="btn form-submit-btn" :style="{ width: '130px' }"
                                        @click="deletelocaldata()" :disabled="localbtnloader">
                                        <span v-if="!localbtnloader"><i class="pi pi-trash me-1"
                                                style="font-size: 0.7rem"></i>Delete Data</span>
                                        <div class="
                                        spinner-border
                                        text-light
                                        custom-spinner-loader-btn
                                    " role="status" v-if="localbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-6 text-end" v-if="clearbtnflag">
                                <div class="custom-form-group" style="margin-top: 26px;">
                                    <label class="form-label"></label>
                                    <button type="button" class="btn custom-view-detail-btn"
                @click="searchvoterclearbtn()">Clear All
            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" v-if="mobiledetailshowflag">
                    <div v-if="this.masterdata != ''">
                        <div class="voter-exit-box-outer mb-3" v-if="credit_scoreresponse">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group mb-0">
                                        <label class="individual-header-label">Credit Score</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <div class="from-label-value text-capitalize">
                                                    {{ credit_scoreresponse.data.credit_score ? credit_scoreresponse.data.credit_score : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer pb-3" v-if="this.masterdata == ''">
                        <div>No records found.</div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="norecordsmessagedisplay">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            btnloader: false,
            disabledinput: false,
            errormessagedisplay:false,
            mobiledetailshowflag: false,
            uanhistoryresponse: '',
            inquiry_request_info: '',
            ccr_response: '',
            identity_info_email_address_info: [],
            id_and_contact_info: '',
            nodatafoundflag: false,
            localdatafound: false,
            localbtnloader: false,
            upidataresponse: '',
            prefillresponse: '',
            uaninforesponse: '',
            credit_scoreresponse:'',
            firstname: '',
            errormessage:'',
            pancardnumber:'',
            masterdata: '',
            norecordsmessagedisplay: false,
            prefillresponsevalue: '',
            clearbtnflag: '',
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        searchmobilewithcreditscore() {
            this.btnloader = true;
            let fields = {};
            fields["mobile_number"] = this.searchmobile;
            if (this.firstname) {
                fields["name"] = this.firstname;
            }
            if (this.pancardnumber) {
                fields["pan_no"] = this.pancardnumber;
            }
            this.ApiService.getcreditscore(fields).then((data) => {
                if (data.status === true) {
                    if (data.datastatus == 1) {
                        this.localdatafound = true;
                    } else {
                        this.localdatafound = false;
                    }
                    this.btnloader = false;
                    this.masterdata = data.data.response;
                    if (this.masterdata != '') {
                        if(data.data.employement_uan_response) {
                            let checkemployement = JSON.parse(data.data.employement_uan_response);
                            if(checkemployement.success == true) {
                                this.uanhistoryresponse = JSON.parse(data.data.employement_uan_response);
                            } else {
                                this.uanhistoryresponse = '';
                            }
                        }

                        if(data.data.name_details_response) {
                            let checknamedetails = JSON.parse(data.data.name_details_response);
                            if(checknamedetails.success == true) {
                                this.upidataresponse = JSON.parse(data.data.name_details_response);
                            } else {
                                this.upidataresponse = '';
                            }
                        }
                        if(data.data.prefill_Details) {
                            let checkprefill = JSON.parse(data.data.prefill_Details);
                            if(checkprefill.success == false) {
                                this.errormessagedisplay = false;
                                this.norecordsmessagedisplay = true;
                            } else {
                                this.prefillresponse = JSON.parse(data.data.prefill_Details);
                                this.norecordsmessagedisplay = false;
                            }
                        }
                        
                        if(data.data.uan_details_response) {
                            let checkunadetail = JSON.parse(data.data.uan_details_response);
                            if(checkunadetail.success == true) {
                                this.uaninforesponse = JSON.parse(data.data.uan_details_response);
                            } else {
                                this.uaninforesponse = ''; 
                            }
                        }
                        if(data.data.response) {
                            let checkcreditscrore = JSON.parse(data.data.response);
                            if(checkcreditscrore.success == true) {
                                this.credit_scoreresponse = JSON.parse(data.data.response);
                            } else {
                                this.credit_scoreresponse = ''; 
                            }
                        }
                    }
                    this.norecordsmessagedisplay = false;
                    this.mobiledetailshowflag = true;
                    this.disabledinput = true;
                    this.errormessagedisplay = false;
                    this.clearbtnflag = true;
                } else {
                    this.btnloader = false;
                    this.mobiledetailshowflag = false;
                    this.errormessage = data.message;
                    this.errormessagedisplay = true;
                    this.norecordsmessagedisplay = true;
                    this.clearbtnflag = false;
                }
            });
        },
        deletelocaldata() {
            let fields = {};
            fields["mobile_number"] = this.searchmobile;
            this.ApiService.deleteprefilldata(fields).then((data) => {
                this.localbtnloader = true;
                if (data.status === true) {
                    this.localbtnloader = false;
                    this.mobiledetailshowflag = false;
                    this.searchmobile = '';
                    var successMsg = "Deleted successfully";
                    this.$toast.open({
                        message: successMsg,
                        type: "success",
                        duration: 3000,
                        position: "top-right",
                    });
                    this.localdatafound = false;
                    this.disabledinput = false;
                } else {
                    this.localbtnloader = false;
                }
            });
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formSearchMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        isLetterWithOutSpace(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z]+$/.test(char)) return true;
            else e.preventDefault();
        },
        searchvoterclearbtn() {
            this.searchmobile = '';
            this.btnloader = false;
            this.disabledinput = false;
            this.mobiledetailshowflag = false;
            this.uanhistoryresponse = '';
            this.inquiry_request_info = '';
            this.ccr_response = '';
            this.identity_info_email_address_info = [];
            this.id_and_contact_info = '';
            this.nodatafoundflag = false;
            this.localdatafound = false;
            this.localbtnloader = false;
            this.upidataresponse = '';
            this.prefillresponse = '';
            this.uaninforesponse = '';
            this.ecivoterinforesponse = '';
            this.firstname = '';
            this.masterdata = '';
            this.clearbtnflag = false;
            this.errormessagedisplay = false;
            this.credit_scoreresponse = '';
            this.errormessage = '';
            this.pancardnumber = '';
            this.norecordsmessagedisplay = false;
            this.prefillresponsevalue = '';
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}

.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}

.dividerrowhide .p-divider {
    display: none;
}

.form-submit-btn.btn {
    background: #ef8080 0% 0% no-repeat padding-box;
    border-color: #de2525;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 15px;
    font-family: "AcuminPro-Regular";
    min-width: 110px;
}

.form-submit-btn:focus {
    box-shadow: none;
}
</style>